import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import '../containers/Data.css';




class PatientMotor2 extends Component { 

    constructor(props) {
        super(props);
        this.state = {
        expanded:false
        };
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {    
        const { background,vehicle_plate,dni,full_name,vehicle_type,vehicle_brand,vehicle_model,vehicle_year,vehicle_color,owner,branch,insurance_company, vip } = this.props;  
        
        return(        
            <Card style={{width:300, margin:10, padding:10, backgroundColor:background}} >
                <CardContent>
                    <Typography variant="h5" component="p">
                    <i className="fas fa-car"></i>  
                    </Typography>
                    <Typography color="textSecondary" variant="h7" component="p">
                        {vehicle_plate}         
                    </Typography>
                    <Typography style={{color:'#333', fontSize:'1.5rem'}} variant="h5" component="h2">
                        {full_name}               
                    </Typography>
                    <Typography color="textSecondary">
                        CI: {dni}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        {insurance_company}
                    </Typography>        
                    <Typography variant="h6" component="p">
                        {vip}         
                    </Typography>                    
                    </CardContent>
                    <CardActions>
                    <Button onClick={this.handleExpandClick} size="small">Más detalles</Button>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>    
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Placa:</strong> {vehicle_plate}                
                        </Typography>              
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Tipo Vehículo:</strong> {vehicle_type}               
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Marca:</strong> {vehicle_brand}                
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Modelo:</strong> {vehicle_model}                
                        </Typography> 
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Año:</strong> {vehicle_year}                
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Model:</strong> {vehicle_model}                
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Color:</strong> {vehicle_color}                
                        </Typography>   
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Contratante:</strong> {owner}                
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Ramo:</strong> {branch}                
                        </Typography>                    
                </CardContent>
                </Collapse>
            </Card>
        );
      }    
    
}


export default PatientMotor2;
