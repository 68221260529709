import React, { Component } from 'react';
import '../containers/Data.css';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { formatDate } from '../utils/formatDate'

class PatientBestDoctors extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }


    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { background, FirstName, LastName, BirthDate, PhoneNumber, type, vip, Identification, Identification2 } = this.props
        return (
            <Card style={{ width: 300, margin: 10, padding: 10, backgroundColor: background, borderColor: '#8B9CAF', borderRadius: '5' }} >
                <CardContent>
                    <Typography style={{ color: '#333', fontSize: '1.5rem' }} variant="h5" component="h2">
                        {FirstName} {LastName}
                    </Typography>
                    <Typography color="textSecondary">
                        Poliza: {Identification ? Identification : Identification2}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        Best Doctors
                    </Typography>
                    <Typography variant="h6" component="p">
                        {vip}
                    </Typography>
                    <Typography color="textSecondary" variant="h7" component="p">
                        {type}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={this.handleExpandClick} size="small">Más detalles</Button>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography style={{ textAlign: 'left' }} variant="h7" component="p">
                            <strong>Tipo póliza:</strong> ESTANDAR
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} variant="h7" component="p">
                            <strong>Ramo:</strong> ELITE
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component="p">
                            <strong>Fecha nacimiento:</strong> {formatDate(BirthDate)}
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} variant="h7" component="p">
                            <strong>Teléfono:</strong> {PhoneNumber}
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} variant="h7" component="p">
                            <strong>Contratante:</strong>
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}
export default PatientBestDoctors;