import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { formatDate } from '../utils/formatDate'
import '../containers/Data.css';




class PatientGps2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { background, dni, address, full_name, birth_date, device_phone, patient_phone, contact_name_1, contact_name_2, contact_name_3, contact_phone_1, contact_phone_2, contact_phone_3, relationship_1, relationship_2, relationship_3 } = this.props;

        return (
            <Card style={{ width: 300, margin: 10, padding: 10, backgroundColor: background }} >
                <CardContent>
                    <Typography variant="h5" component="p">
                        <i className="fas fa-map-marked-alt"></i>
                    </Typography>
                    <Typography color="textSecondary" variant="h7" component="p">
                        {device_phone}
                    </Typography>
                    <Typography style={{ color: '#333', fontSize: '1.5rem' }} variant="h5" component="h2">
                        {full_name}
                    </Typography>
                    <Typography color="textSecondary">
                        CI: {dni}
                    </Typography>
                    <Typography variant="h6" component="p">
                        Tracker GPS
                    </Typography>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        {address}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={this.handleExpandClick} size="small">Más detalles</Button>
                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography style={{ textAlign: 'left' }} component="p">
                            <strong>Teléfono Px:</strong> {patient_phone}
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component="p">
                            <strong>Fecha nacimiento:</strong> {formatDate(birth_date)}
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component="p">
                            <strong>{relationship_1}</strong> {contact_name_1} -  {contact_phone_1}
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component="p">
                            <strong>{relationship_2}</strong> {contact_name_2} -  {contact_phone_2}
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component="p">
                            <strong>{relationship_3}</strong> {contact_name_3} -  {contact_phone_3}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        );
    }

}


export default PatientGps2;
