import React, { Component } from "react";
import { Route, Redirect } from 'react-router'
import { connect } from "react-redux";

const AuthRoute = ({
    component: Component,
    isAuthenticated
  }) => (
    <Route      
      render={props =>
        isAuthenticated    ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/signin'
            }}
          />
        )
      }
    />
  )

  const mapStateToProps = state => ({    
    isAuthenticated: state.currentUser.isAuthenticated
  })

  export default connect(mapStateToProps)(AuthRoute)


