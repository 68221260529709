import React, { Component } from 'react';
import { connect } from "react-redux";
import { findPatients } from "../store/actions/patients";
import { css } from 'react-emotion';
import { BeatLoader } from 'react-spinners';
import {FormControlLabel, Grid, Switch, Button} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';


const override = css`
    text-align: center;
    margin-top:5px;
`;

  class Search extends Component {
    static defaultProps = {
      onData() {}
    }
    constructor() {
      super();
      this.state = {
        dni: '',
        status: '',
        bestDoctors: false,
        birthDate: '',
        dateValue: '',
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    handleChange = event => {
      this.setState({bestDoctors: event.target.checked}, () => {
        if(this.state.bestDoctors === false){
          this.setState({birthDate: '', dateValue: ''})
        }
      })
    }
    
    handleSubmit = e => {
      e.preventDefault();
      this.props.findPatients(this.state.bestDoctors, this.state.dni, this.state.birthDate);     
    }

    handleDateChange = (e, date) => {
      const formatBirthDate = moment(date, 'DD-MM-YYYY').format('M-DD-YYYY'),
            dateValue = moment(date, 'DD-MM-YYYY');
      this.setState({birthDate: formatBirthDate, dateValue });
    }

    render() {
      const { dni } = this.state;
      return (
        <div className='searchContainer'>
          <form className='searchForm' onSubmit={this.handleSubmit}>
            <Grid 
                  container 
                  spacing={3}
                  justify="center"
                  alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <input
                        type="text"
                        name="dni"
                        required
                        placeholder="C.I / Tlf. / Placa / Poliza"
                        value={dni}
                        onChange={(e) => this.setState({[e.target.name]: e.target.value })}/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.bestDoctors}
                        onChange={this.handleChange}
                        value={this.state.bestDoctors}
                        color="primary"/>
                    }
                    style={{ marginLeft: '0 !important' }}
                    label="Best Doctors"/>
                </Grid>
                {this.state.bestDoctors &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        minDate={new Date("1900-01-01")}
                        maxDate={new Date()}
                        value={ this.state.dateValue ? this.state.dateValue : null }
                        margin="normal"
                        required
                        id="date-picker-dialog"
                        format="DD-MM-YYYY"
                        onChange={this.handleDateChange}
                      />
                  </MuiPickersUtilsProvider>
                </Grid>
                }
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button 
                        fullWidth
                        type="submit" 
                        variant="contained" 
                        color="secondary"
                        size="large">
                    <i className="fas fa-search" style={{marginRigth: 10}}></i>  
                    Buscar
                  </Button>
                </Grid>
                <BeatLoader
                  className={override}
                  sizeUnit={"px"}
                  size={15}
                  color={'#E32726'}
                  loading={this.props.loading.loading}/>
            </Grid>
          </form>
          {this.props.errors.message && (
            <div className="errormsgSearch"><i className="fas fa-exclamation-triangle"></i> {this.props.errors.message}</div>
          )}
        </div>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      errors: state.errors,
      loading: state.loading
    };
  }
  export default connect(mapStateToProps, { findPatients })(Search);
