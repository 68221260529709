export const ADD_ERROR = 'ADD_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOAD_PATIENTS = 'LOAD_PATIENTS';
export const GET_PATIENTS = 'GET_PATIENTS';
export const RESET_PATIENTS = 'RESET_PATIENTS';
export const ADD_PATIENTS = 'ADD_PATIENTS';
export const LOAD_BENEFICIARIES = 'LOAD_BENEFICIARIES';
export const RESET_BENEFICIARIES = 'RESET_BENEFICIARIES';
export const QUERY_RESULT = 'QUERY_RESULT';
export const SET_TYPE = 'SET_TYPE';
export const SET_LOADING = 'SET_LOADING';
