import React, { Component } from 'react';
import { connect } from "react-redux";
import PatientItem from '../components/PatientItem2';
import PatientGps from '../components/PatientGps2';
import PatientMotor from '../components/PatientMotor2';
import PatientBestDoctors from '../components/PatientBestDoctors';
import './Data.css';
import { Link } from "react-router-dom";


class Data extends Component {

  state = { expanded: false };

  handleExpandClick() {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {

    let patients = [];
    if (this.props.searchType.type === 'gps') { //tracker GPS
      patients = this.props.patients.map((patient, index) => (
        <PatientGps
          background='rgba(139, 156, 175, 0.5)'
          key={index}
          {...patient}
        />
      ));

      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          {this.props.currentUser.user.role === 'admin' && (<Link className='upload' to='/upload'> <i className="fas fa-cloud-upload-alt"> <span className='lighter'> CARGAR DATA </span></i> </Link>)}
          <h3> {this.props.messages.patients} </h3>
          <div className="data">
            {patients}
          </div>
        </div>
      );

    } else if (this.props.searchType.type === 'motor') { //Gato Motor
      patients = this.props.patients.map((patient, index) => (
        <PatientMotor
          vip={patient.vehicle_vip === 'VIP' ? <i style={{ color: '#ffcc00' }} class="fas fa-star"> <span class="textFormat" style={{ color: 'black' }}>VIP</span> </i> : 'STANDARD'}
          key={index}
          background='rgba(139, 156, 175, 0.5)'
          {...patient}
        />
      ));
      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          {this.props.currentUser.user.role === 'admin' && (<Link className='upload' to='/upload'> <i className="fas fa-cloud-upload-alt"> <span className='lighter'> CARGAR DATA </span></i> </Link>)}
          <h3> {this.props.messages.patients} </h3>
          <div className="data">
            {patients}
          </div>
        </div>
      );

    }
    else if (this.props.searchType.type === 'doctors') { //Gato Motor
      patients = this.props.patients.map((patient, index) => (
        <PatientBestDoctors
          vip={<i style={{ color: '#ffcc00' }} className="fas fa-star"> <span className="textFormat" style={{ color: 'black' }}>VIP - ESTANDAR</span> </i>}
          key={index}
          background='rgba(139, 156, 175, 0.5)'
          {...patient}
        />
      ));
      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h3> {this.props.messages.patients} </h3>
          <div className="data">
            {patients}
          </div>
        </div>
      );
    }
    else { //Normal patients
      patients = this.props.patients.map((patient, index) => (
        <PatientItem
          background='rgba(139, 156, 175, 0.5)'
          key={index}
          vip={patient.policy_category === 'VIP' ? <i style={{ color: '#ffcc00' }} className="fas fa-star"> <span className="textFormat" style={{ color: 'black' }}>{patient.policy_category}</span> </i> : patient.policy_category}
          {...patient}
        />

      ));
      const beneficiaries = this.props.beneficiaries.map((patient, index) => (
        <PatientItem
          background='#f2f2f2'
          vip={patient.policy_category === 'VIP' ? <i style={{ color: '#ffcc00' }} className="fas fa-star"> <span className="textFormat" style={{ color: 'black' }}>{patient.policy_category}</span> </i> : patient.policy_category}
          key={index}
          {...patient}
        />
      ));
      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          {this.props.currentUser.user.role === 'admin' && (<Link className='upload' to='/upload'> <i className="fas fa-cloud-upload-alt"> <span className='lighter textFormat'> CARGAR DATA </span></i> </Link>)}
          <h3> {this.props.messages.patients} </h3>
          <div className="data"> {patients} </div>
          <div className="data"> {beneficiaries} </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    patients: state.patients,
    beneficiaries: state.beneficiaries,
    currentUser: state.currentUser,
    messages: state.messages,
    searchType: state.searchType
  };
}


export default connect(mapStateToProps)(Data);
