import React, { Component } from 'react';
import { connect } from "react-redux";
import { getPatients } from "../store/actions/patients";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Fab
} from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

class PatientsList extends Component {

    state = { loaded: false };
    
    styles =  {
        fab: {
            margin: 1,
        },

        table: {
            width: '50%',
            position: 'absolute',
            left: '25%'
        }
    }

    componentDidMount()  {     
        if (!this.state.loaded) {
            this.props.getPatients();    
            this.setState({
                loaded: true
            });
        } 
    }

    render() {
        return (            
            <Paper>
                <Table style={this.styles.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Compañia de Seguro</TableCell>
                            <TableCell align="center">Código</TableCell>
                            <TableCell align="center">Cartera</TableCell>
                            <TableCell align="center">Acción</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.patients.map((patient, index) => (
                            <TableRow key={index}>
                                <TableCell align="center" component="th" scope="row">
                                {patient.insurance_company}
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                {patient.insurance_code}
                                </TableCell>
                                <TableCell align="center">{patient.count}</TableCell>
                                <TableCell align="center">
                                    <Fab color="primary" 
                                        aria-label="export" 
                                        size="small" 
                                        title={`exportar ${patient.insurance_company}`} 
                                        // className={this.styles.fab}
                                        >
                                        <CloudDownload/>
                                    </Fab>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

function mapStateToProps(state) {
    return {
      patients: state.patients
    };
}  

export default connect(mapStateToProps, { getPatients })(PatientsList);
