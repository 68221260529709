import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import '../containers/Data.css';




class PatientItem2 extends Component { 

    constructor(props) {
        super(props);
        this.state = {
        expanded:false
        };
    }

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {    
        const { vip, background,dni,full_name,birth_date,location,type,owner,branch,insurance_company, policy_type } = this.props;  
        
        return(        
            <Card className="textFormat" style={{width:300, margin:10, padding:10, backgroundColor:background}} >
                <CardContent>
                    <Typography variant="h5" component="p">
                        <i className="fas fa-user-md"></i>  
                    </Typography>
                    <Typography style={{color:'#333', fontSize:'1.5rem'}} variant="h5" component="h2">
                        {full_name}               
                    </Typography>
                    <Typography color="textSecondary">
                        CI: {dni}
                    </Typography>
                    <Typography variant="h6" color="textSecondary" gutterBottom>
                        {insurance_company}
                    </Typography>        
                    <Typography variant="h6" component="p">
                        {vip} - {policy_type}        
                    </Typography>
                    <Typography color="textSecondary" variant="h7" component="p">
                        {type}         
                    </Typography>
                    </CardContent>
                    <CardActions>
                    <Button onClick={this.handleExpandClick} size="small">Más detalles</Button>
                    </CardActions>
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent> 
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong> Tipo póliza:</strong> {policy_type}           
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Ramo:</strong> {branch}               
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Fecha nacimiento:</strong> {birth_date}                
                        </Typography>  
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Contratante:</strong> {owner}                
                        </Typography>
                        <Typography style={{textAlign:'left'} } component="p">
                            <strong>Ubicación:</strong> {location}                
                        </Typography>                        
                </CardContent>
                </Collapse>
            </Card>
        );
      }    
    
}


export default PatientItem2;
