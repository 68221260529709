import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import './MenuLeft.css';
import { resetAllPatients } from "../store/actions/patients";
class MenuLeft extends Component {

    state = {
        open: false,
        isActive: true,
    }

    handleClick = (e) => {
        this.setState({ open: true, isActive: false });
        document.getElementById("left-sidenav").style.width = "0";
    }

    handleClickHome = (e) => {
        this.props.resetAllPatients();
        this.setState({
            open: true,
            isActive: true,
        })
        document.getElementById("left-sidenav").style.width = "0";
    }

    openNav = (e) => {
        e.preventDefault()
        this.setState({
            open: false,
        })
        document.getElementById("left-sidenav").style.width = "250px";
    }

    closeNav = (e) => {
        e.preventDefault()
        this.setState({
            open: true,
        })
        document.getElementById("left-sidenav").style.width = "0";  
    }

    styleOpen = {
        position: 'absolute',
        fontSize:30,
        cursor: "pointer",
        top: '45%',
    }

    styleOpenClose = {
        fontSize:30,
        cursor: "pointer",
    }

  render() {
    return (
      <div>
        {this.props.currentUser.isAuthenticated && this.props.currentUser.user.role === 'admin' &&
        <div>
            {this.state.open && 
                <div className="open">
                    <span style={this.styleOpen} onClick={this.openNav} title="Haga click aqui">&#9776;</span>
                </div>
            }
            <div id="left-sidenav" className="sidenav">
                {!this.state.open && 
                    <span style={this.styleOpenClose} className="closebtn" onClick={this.closeNav}>&times;</span>
                }
                <div className="home">
                    <Link to="/"  onClick={this.handleClickHome} className={this.state.isActive ? 'is_active' : 'default'}><i className="fas fa-home"></i><span>Inicio</span></Link>
                </div>
                <div className="patients">
                    <Link to="/list-patients" onClick={this.handleClick} className={!this.state.isActive ? 'is_active' : 'default'}><i className="fas fa-chart-pie"></i><span>Pacientes</span></Link>
                </div>
            </div>
        </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser
  };
}

export default connect(mapStateToProps, {resetAllPatients})(MenuLeft);
